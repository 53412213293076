import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { deleteTeam, joinTeam, requestInviteToTeam } from '@/graphql/mutations'
import store from '@/store'

export default () => {
  const delTeam = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Hapus tim?',
      body: 'Konfirmasi jika anda ingin menghapus tim.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: deleteTeam,
          variables: {
            id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus tim!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      }
    })
  })

  const jTeam = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Gabung tim?',
      body: 'Konfirmasi jika anda ingin bergabung tim.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: joinTeam,
          variables: {
            team_id: id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil bergabung ke dalam tim!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      }
    })
  })

  const requestJoin = id => new Promise((resolve, reject) => {
    Vue.$dialog({
      title: 'Request join tim?',
      body: 'Konfirmasi jika anda ingin request join tim.',
    }).then(confirm => {
      if (confirm) {
        apolloClient.mutate({
          mutation: requestInviteToTeam,
          variables: {
            team_id: id,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil request join ke team!',
          })
          resolve(result)
        }).catch(err => {
          reject(err)
        })
      }
    })
  })

  return {
    delTeam,
    jTeam,
    requestJoin,
  }
}
