<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        v-model="search"
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari tim diarsipkan"
        @input="searchTeams"
        @close="search = ''; fetchTeams()"
      />
    </div>
    <v-card
      v-if="loading"
      outlined
      class="pa-4 my-4 d-flex align-center justify-center"
      height="120px"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-card>
    <div
      v-else
      class="list mt-4"
    >
      <div
        v-if="teamList.length"
      >
        <team-card
          v-for="team in teamList"
          :key="team.id"
          :data="team"
          is-archived
          class="mb-2"
          @restore="restore"
        />
      </div>
      <v-card
        v-else
        outlined
        class="pa-4 my-4 d-flex align-center justify-center"
        height="120px"
      >
        <span>
          {{ search.value ? 'Tidak ada tim ditemukan' : 'Belum ada tim' }}
        </span>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { onMounted, ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import { apolloClient } from '@/vue-apollo'
import { teams } from '@/graphql/queries'
import { restoreTeam } from '@/graphql/mutations'
import store from '@/store'
import TeamCard from './TeamCard.vue'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    InputIconToggle,
    TeamCard,
  },
  setup(props, { emit }) {
    const search = ref('')
    const teamList = ref([])
    const loading = ref(true)

    const fetchTeams = () => {
      loading.value = true
      apolloClient.query({
        query: teams,
        variables: {
          search: search.value ? search.value : null,
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter: {
            deleted_team: true,
          },
          pagination: {
            limit: 100,
            offset: 0,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loading.value = false
        teamList.value = result.data.teams
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }

    const searchTeams = useDebounceFn(() => {
      fetchTeams()
    }, 1000)

    const restore = data => {
      Vue.$dialog({
        title: 'Pulihkan tim?',
        body: 'Tim akan dipulihkan dan kembali di daftar tim',
      }).then(confirm => {
        if (confirm) {
          loading.value = true
          apolloClient.mutate({
            mutation: restoreTeam,
            variables: {
              id: data,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            loading.value = false
            fetchTeams()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan tim!',
            })
            emit('refetch', result)
          }).catch(err => {
            loading.value = false
            errorHandling(err)
          })
        }
      })
    }

    onMounted(() => {
      fetchTeams()
    })

    return {
      search,
      teamList,
      loading,
      fetchTeams,
      searchTeams,
      restore,

      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
