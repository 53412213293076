<template>
  <v-card outlined>
    <v-data-table
      :headers="tableColumns"
      :items="data"
      hide-default-header
      hide-default-footer
    >
      <template v-slot:item.name="props">
        <div class="d-flex align-center">
          <v-avatar
            size="36"
            class="v-avatar-light-bg mr-2"
          >
            <v-img
              v-if="props.item.user.photo"
              :src="props.item.user.photo"
            />
            <span v-else>{{ avatarText(`${props.item.user.first_name} ${props.item.user.last_name}`) }}</span>
          </v-avatar>
          <div class="d-flex flex-column">
            <router-link :to="{ name: 'user-detail', params: { id: props.item.user.id } }">
              <span class="primary--text font-medium">{{ props.item.user.name }}</span>
            </router-link>
            <small class="text--disabled">{{ props.item.user.email }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.role="props">
        <span class="font-medium">
          {{ props.item.team.name }}
        </span>
      </template>
      <template v-slot:item.action="props">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="20px"
              v-bind="attrs"
              class="ml-auto"
              v-on="on"
            >
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              @click="$emit('accept', props.item)"
            >
              Terima
            </v-list-item>
            <v-list-item
              class="error--text"
              @click="$emit('reject', props.item)"
            >
              Tolak
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import { formatDate, avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup() {
    const { rootThemeClasses } = useVuetify()
    const tableColumns = ref([
      {
        text: 'Nama',
        value: 'name',
        class: 'w-vdt-220',
      },
      {
        text: 'Role',
        value: 'role',
        width: '180px',
      },
      {
        text: '',
        value: 'action',
      },
    ])

    return {
      rootThemeClasses,
      formatDate,
      tableColumns,
      avatarText,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style>

</style>
