<template>
  <v-dialog
    v-model="showForm"
    max-width="600px"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">
          {{ updateMode ? 'Ubah Tim' : 'Buat Tim' }}
        </span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="teamForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="teamFormData.name"
            :rules="[required, maxLength(teamFormData.name, 50)]"
            label="Nama (wajib diisi)"
            placeholder="Input nama tim"
            dense
            outlined
            counter="50"
          />
          <v-textarea
            v-model="teamFormData.description"
            :rules="[maxLength(teamFormData.description, 200)]"
            label="Deskripsi"
            placeholder="Isi deskripsi tim"
            outlined
            dense
            counter="200"
          />
          <v-switch
            v-model="teamFormData.is_public"
            inset
            flat
            hide-details
            label="Public"
            class="mb-5 mt-0"
          />
          <v-switch
            v-model="teamFormData.channel_permission"
            inset
            flat
            hide-details
            label="Channel Permission"
            class="my-5"
          />
          <user-auto-suggest
            v-model="teamFormData.user_id"
            label="Pilih anggota tim"
            multiple
            :max="20"
            :min="2"
            :rules="[required(teamFormData.user_id)]"
          />
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="reset"
          >
            Reset Form
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingSubmit"
            @click="submitForm"
          >
            Simpan Tim
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, maxLength } from '@core/utils/validation'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import { apolloClient } from '@/vue-apollo'
import { createTeam, updateTeam } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  components: {
    UserAutoSuggest,
  },
  setup(props, { emit }) {
    const showForm = ref(false)
    const teamForm = ref(null)
    const teamFormData = ref({
      team_id: null,
      name: null,
      description: null,
      user_id: [],
      is_public: false,
      channel_permission: false,
    })
    const loadingSubmit = ref(false)
    const updateMode = ref(false)

    const resetValidation = () => teamForm.value.resetValidation()
    const show = () => {
      showForm.value = true
    }

    const update = team => {
      console.log(team)
      showForm.value = true
      updateMode.value = true

      teamFormData.value = {
        team_id: team.id,
        name: team.name,
        description: team.description,
        user_id: team.users,
        is_public: team.is_public,
        channel_permission: team.channel_permission,
      }
    }

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false

      teamFormData.value = {
        team_id: null,
        name: null,
        description: null,
        user_id: [],
      }

      teamForm.value.reset()
    }

    const close = () => {
      showForm.value = false
      reset()
    }

    const submitForm = () => {
      if (teamForm.value.validate()) {
        loadingSubmit.value = true

        if (updateMode.value) {
          apolloClient.mutate({
            mutation: updateTeam,
            variables: {
              ...teamFormData.value,
              user_id: teamFormData.value.user_id.map(value => value.id),
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah tim!',
            })
            emit('success', result)
            loadingSubmit.value = false
            close()
          }).catch(err => {
            errorHandling(err)
            loadingSubmit.value = false
          })
        } else {
          apolloClient.mutate({
            mutation: createTeam,
            variables: {
              ...teamFormData.value,
              user_id: teamFormData.value.user_id.map(value => value.id),
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil membuat tim baru',
            })
            emit('success', result)
            loadingSubmit.value = false
            close()
          }).catch(err => {
            errorHandling(err)
            loadingSubmit.value = false
          })
        }
      }
    }

    return {
      showForm,
      teamFormData,
      teamForm,
      loadingSubmit,
      updateMode,
      reset,
      update,

      resetValidation,
      show,
      close,
      submitForm,

      required,
      maxLength,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
