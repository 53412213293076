<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col>
          <h3>Daftar Tim</h3>
        </v-col>
        <v-col
          cols="auto"
          class="ms-auto d-flex"
        >
          <v-menu
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiBell }}
                  </v-icon>
                </template>
                <span>Request Join Team</span>
              </v-tooltip>
            </template>
            <team-request @refetch="fetchTeams()" />
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Tim dihapus / diarsipkan</span>
              </v-tooltip>
            </template>
            <team-archive @refetch="fetchTeams()" />
          </v-menu>
          <input-icon-toggle
            v-model="state.teamFilter.value.search"
            class="ms-2"
            :icon="icons.mdiMagnify"
            placeholder="Cari tim"
            @close="state.teamFilter.value.search = ''; fetchTeams()"
            @input="searchTeams"
          />
        </v-col>
      </v-row>
      <v-row
        class="mx-2 mb-2"
      >
        <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <v-select
            v-model="state.teamFilter.value.sort"
            placeholder="Sortir Berdasarkan"
            :items="sortOptions"
            item-text="label"
            return-object
            outlined
            dense
            hide-details
            @change="fetchTeams()"
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-auto ms-md-4"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$refs.teamForm.show()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Buat Tim</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-tabs-items v-model="viewTab">
        <v-tab-item>
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="state.teams.value"
            :items-per-page="10"
            item-key="id"
            disable-sort
            :options.sync="state.teamFilter.value.pagination"
            :footer-props="{
              'items-per-page-options': [10, 20, 30],
              'items-per-page-text': 'Jumlah data',
            }"
            mobile-breakpoint="0"
            :loading="state.loadingTeam.value"
          >
            <template v-slot:item.name="props">
              <span class="font-medium d-block">{{ props.item.name }}</span>
              <small class="text--disabled">{{ props.item.description }}</small>
            </template>
            <template v-slot:item.visibility="props">
              <v-chip
                v-if="props.item.is_public"
                color="success"
                small
              >
                Publik
              </v-chip>
              <v-chip
                v-else
                color="secondary"
                small
              >
                Privat
              </v-chip>
            </template>
            <template v-slot:item.users="props">
              <limit-elements
                :elements="props.item.users"
                :limit="3"
                class="v-avatar-group"
                :class="rootThemeClasses"
              >
                <template #default="{ data }">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="32"
                        class="mb-1"
                        v-on="on"
                      >
                        <v-img src="http://placekitten.com/140/110" />
                      </v-avatar>
                    </template>
                    <span>{{ `${data.first_name} ${data.last_name}` }}</span>
                  </v-tooltip>
                </template>
                <template #others="{ data }">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="32"
                        v-on="on"
                      >
                        <v-img src="http://placekitten.com/140/110" />
                      </v-avatar>
                    </template>
                    <span>{{ `${data.first_name} ${data.last_name}` }}</span>
                  </v-tooltip>
                </template>
                <template #others-activator="{ data }">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="32"
                        color="white text-caption primary--text"
                        v-on="on"
                      >
                        +{{ data.limit }}
                      </v-avatar>
                    </template>
                    <span>Lihat Anggota Lain</span>
                  </v-tooltip>
                </template>
              </limit-elements>
            </template>
            <template v-slot:item.action="props">
              <v-menu
                v-if="props.item.users.filter((d) => d.id === $store.getters.getUserData.id).length"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item>
                    <router-link :to="{ name: 'team-detail', params: { id: props.item.id } }">
                      Lihat Detail
                    </router-link>
                  </v-list-item>
                  <v-list-item
                    @click="$refs.teamMemberList.update(props.item)"
                  >
                    Daftar Member
                  </v-list-item>
                  <v-list-item
                    v-if="props.item.user.id === $store.getters.getUserData.id"
                    @click="$refs.teamForm.update(props.item)"
                  >
                    Ubah Data
                  </v-list-item>
                  <v-list-item
                    v-if="props.item.user.id === $store.getters.getUserData.id"
                    class="error--text"
                    @click="deleteTeam(props.item.id)"
                  >
                    Hapus Tim
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                v-else
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="20px"
                    v-bind="attrs"
                    class="ml-auto"
                    v-on="on"
                  >
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!props.item.is_public"
                    @click="handleRequestJoin(props.item.id)"
                  >
                    Request Join
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="handleJoin(props.item.id)"
                  >
                    Gabung Tim
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item class="mx-4">
          <v-data-iterator
            :items="state.teams.value"
            :items-per-page="10"
            item-key="id"
            show-select
            disable-sort
            :footer-props="{
              'items-per-page-options': [10, 20, 30],
              'items-per-page-text': 'Jumlah data',
            }"
            mobile-breakpoint="0"
            :loading="state.loadingTeam.value"
          >
            <template v-slot:default="props">
              <v-row class="match-height">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                >
                  <team-card
                    :data="item"
                    @request-join="handleRequestJoin(item.id)"
                    @join="handleJoin(item.id)"
                    @update="$refs.teamForm.update(item)"
                    @member="$refs.teamMemberList.update(item)"
                    @delete="deleteTeam(item.id)"
                  />
                </v-col>
              </v-row>
            </template>
            <template
              v-slot:footer.page-text="page"
            >
              Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
            </template>
          </v-data-iterator>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <team-form
      ref="teamForm"
      @success="fetchTeams()"
    />

    <team-member-list
      ref="teamMemberList"
      @success="fetchTeams()"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify, mdiTable, mdiCardText, mdiPlus, mdiDotsVertical, mdiArchive, mdiBell,
} from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { useStorage, useDebounceFn } from '@vueuse/core'
import useVuetify from '@core/utils/vuetify'
import { formatDate } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { teams } from '@/graphql/queries'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import TeamForm from './TeamForm.vue'
import useTeam from './useTeam'
import TeamCard from './TeamCard.vue'
import TeamArchive from './TeamArchive.vue'
import TeamRequest from './TeamRequest.vue'
import TeamMemberList from './TeamMemberList.vue'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    InputIconToggle,
    TeamForm,
    LimitElements,
    TeamCard,
    TeamArchive,
    TeamRequest,
    TeamMemberList,
  },
  setup() {
    const state = {
      ...useFieldTeam(['teams', 'loadingTeam', 'teamFilter']),
    }
    const viewTab = useStorage('viewTabTeam', 0)
    const tableColumns = ref([
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Visibilitas',
        value: 'visibility',
      },
      {
        text: 'Anggota',
        value: 'users',
      },
      {
        text: '',
        value: 'action',
      },
    ])
    const selectedRows = ref([])
    const { rootThemeClasses } = useVuetify()
    const { delTeam, jTeam, requestJoin } = useTeam()
    const sortOptions = ref([
      {
        label: 'Nama Tim A-Z',
        field: 'name',
        type: 'ASC',
      },
      {
        label: 'Nama Tim Z-A',
        field: 'name',
        type: 'DESC',
      },
      {
        label: 'Deskripsi Tim A-Z',
        field: 'description',
        type: 'ASC',
      },
      {
        label: 'Deskripsi Tim Z-A',
        field: 'description',
        type: 'DESC',
      },
      {
        label: 'Jumlah Anggota Tersedikit',
        field: 'userCount',
        type: 'ASC',
      },
      {
        label: 'Jumlah Anggota Terbanyak',
        field: 'userCount',
        type: 'DESC',
      },
    ])

    const fetchTeams = () => {
      const filter = state.teamFilter.value
      state.loadingTeam.value = true
      apolloClient.query({
        query: teams,
        fetchPolicy: 'no-cache',
        variables: {
          search: filter.search ? filter.search : null,
          workspace_id: store.getters.getCurrentWorkspaceId,
          filter: {
            sort: filter.sort.field,
            order: filter.sort.type,
          },
          pagination: {
            limit: 20,
            offset: 0,
          },
        },
      }).then(result => {
        state.teams.value = result.data.teams
        state.loadingTeam.value = false
      }).catch(err => {
        state.loadingTeam.value = false
        errorHandling(err)
      })
    }

    if (!state.teams.value.length) {
      fetchTeams()
    }

    const deleteTeam = id => {
      delTeam(id).then(() => {
        fetchTeams()
      }).catch(err => errorHandling(err))
    }

    const handleJoin = id => {
      jTeam(id).then(() => {
        fetchTeams()
      }).catch(err => errorHandling(err))
    }

    const handleRequestJoin = id => {
      requestJoin(id).then(() => {
        fetchTeams()
      }).catch(err => errorHandling(err))
    }

    const searchTeams = useDebounceFn(() => {
      fetchTeams()
    }, 1000)

    return {
      state,
      viewTab,
      tableColumns,
      selectedRows,
      rootThemeClasses,
      fetchTeams,
      sortOptions,
      searchTeams,
      handleJoin,
      handleRequestJoin,

      formatDate,

      deleteTeam,

      icons: {
        mdiMagnify,
        mdiTable,
        mdiCardText,
        mdiPlus,
        mdiDotsVertical,
        mdiArchive,
        mdiBell,
      },
    }
  },
}
</script>

<style>

</style>
