<template>
  <v-hover #default="{ hover }">
    <v-card
      outlined
      class="d-flex flex-column flex-grow-1 pa-4"
    >
      <div class="d-flex mb-1 align-start">
        <div>
          <span class="text-subtitle-2 font-weight-bold d-block">{{ data.name }}</span>
          <span class="text-caption text--disabled">Dibuat pada: {{ formatDate(data.created_at, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}</span>
        </div>
        <v-menu
          v-if="data.status === 'joined'"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                v-show="hover"
                size="20px"
                v-bind="attrs"
                class="ml-auto mt-2"
                v-on="on"
              >
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-slide-x-reverse-transition>
          </template>
          <v-list>
            <v-list-item @click="$router.push({ name: 'team-detail', params: { id: data.id } })">
              Lihat Detail
            </v-list-item>
            <v-list-item
              @click="$emit('member', data.id)"
            >
              Daftar Member
            </v-list-item>
            <v-list-item
              v-if="data.user.id === $store.getters.getUserData.id"
              @click="$emit('update', data.id)"
            >
              Ubah Data
            </v-list-item>
            <v-list-item
              v-if="data.user.id === $store.getters.getUserData.id"
              class="error--text"
              @click="$emit('delete', data.id)"
            >
              Hapus Tim
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else-if="data.status === 'not requested'"
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('request-join', data.id)"
        >
          Request Join
        </v-btn>
        <v-btn
          v-else-if="data.status === 'requested'"
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('request-join', data.id)"
        >
          Request Ulang
        </v-btn>
        <v-btn
          v-else-if="data.deleted_at != null"
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('restore', data.id)"
        >
          Pulihkan Tim
        </v-btn>
        <v-btn
          v-else
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('join', data.id)"
        >
          Gabung Tim
        </v-btn>
      </div>
      <span class="text-caption text--disabled">{{ data.description }}</span>
      <limit-elements
        v-if="data && data.users"
        :elements="data.users"
        :limit="3"
        class="v-avatar-group mt-2"
        :class="rootThemeClasses"
      >
        <template #default="item">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                size="32"
                class="mb-1"
                v-on="on"
              >
                <v-img src="http://placekitten.com/140/110" />
              </v-avatar>
            </template>
            <span>{{ item.data.first_name }} {{ item.data.last_name }}</span>
          </v-tooltip>
        </template>
        <template #others="item">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                size="32"
                v-on="on"
              >
                <v-img src="http://placekitten.com/140/110" />
              </v-avatar>
            </template>
            <span>{{ item.data.first_name }} {{ item.data.last_name }}</span>
          </v-tooltip>
        </template>
        <template #others-activator="item">
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                size="32"
                color="white text-caption primary--text"
                v-on="on"
              >
                +{{ item.data.limit }}
              </v-avatar>
            </template>
            <span>Lihat Anggota Lain</span>
          </v-tooltip>
        </template>
      </limit-elements>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import { formatDate } from '@core/utils/filter'
import LimitElements from '@/components/misc/LimitElements.vue'

export default {
  components: {
    LimitElements,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { rootThemeClasses } = useVuetify()

    return {
      rootThemeClasses,
      formatDate,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style>

</style>
