<template>
  <v-card
    width="600px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Request Join Team</span>
    </div>
    <v-card
      v-if="loading"
      outlined
      class="pa-4 my-4 d-flex align-center justify-center"
      height="120px"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-card>
    <div
      v-else
      class="list mt-4"
    >
      <div
        v-if="requestList.length"
      >
        <team-user-card
          :data="requestList"
          is-archived
          class="mb-2"
          @accept="accept"
          @reject="reject"
        />
      </div>
      <v-card
        v-else
        outlined
        class="pa-4 my-4 d-flex align-center justify-center"
        height="120px"
      >
        <span>
          {{ 'Belum ada request' }}
        </span>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { onMounted, ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { teamRequestInvite } from '@/graphql/queries'
import { acceptRequestInvite, rejectRequestInvite } from '@/graphql/mutations'
import store from '@/store'
import TeamUserCard from './TeamUserCard.vue'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    TeamUserCard,
  },
  setup(props, { emit }) {
    const requestList = ref([])
    const loading = ref(true)

    const fetchRequestInvite = () => {
      loading.value = true
      apolloClient.query({
        query: teamRequestInvite,
        fetchPolicy: 'no-cache',
      }).then(result => {
        loading.value = false
        requestList.value = result.data.teamRequestInvite
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }

    const accept = data => {
      Vue.$dialog({
        title: 'Terima permintaan?',
        body: 'User ini akan diizinkan masuk ke dalam tim',
      }).then(confirm => {
        if (confirm) {
          loading.value = true
          apolloClient.mutate({
            mutation: acceptRequestInvite,
            variables: {
              user_id: data.user.id,
              team_id: data.team.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            loading.value = false
            fetchRequestInvite()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menerima user ke dalam tim!',
            })
            emit('refetch', result)
          }).catch(err => {
            loading.value = false
            errorHandling(err)
          })
        }
      })
    }

    const reject = data => {
      Vue.$dialog({
        title: 'Tolak permintaan?',
        body: 'Permintaan gabung dari user ini akan ditolak',
      }).then(confirm => {
        if (confirm) {
          loading.value = true
          apolloClient.mutate({
            mutation: rejectRequestInvite,
            variables: {
              user_id: data.user.id,
              team_id: data.team.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            loading.value = false
            fetchRequestInvite()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menolak permintaan gabung tim!',
            })
            emit('refetch', result)
          }).catch(err => {
            loading.value = false
            errorHandling(err)
          })
        }
      })
    }

    onMounted(() => {
      fetchRequestInvite()
    })

    return {
      loading,
      fetchRequestInvite,
      requestList,
      accept,
      reject,

      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
