import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.data,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"36"}},[(props.item.user.photo)?_c(VImg,{attrs:{"src":props.item.user.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(((props.item.user.first_name) + " " + (props.item.user.last_name)))))])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: props.item.user.id } }}},[_c('span',{staticClass:"primary--text font-medium"},[_vm._v(_vm._s(props.item.user.name))])]),_c('small',{staticClass:"text--disabled"},[_vm._v(_vm._s(props.item.user.email))])],1)],1)]}},{key:"item.role",fn:function(props){return [_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(props.item.team.name)+" ")])]}},{key:"item.action",fn:function(props){return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.$emit('accept', props.item)}}},[_vm._v(" Terima ")]),_c(VListItem,{staticClass:"error--text",on:{"click":function($event){return _vm.$emit('reject', props.item)}}},[_vm._v(" Tolak ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }