<template>
  <v-dialog
    v-model="showForm"
    max-width="800px"
    scrollable
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">
          Daftar Member Team
        </span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Member"
        single-line
        hide-details
        class="px-5 mb-3 mt-0"
      />
      <v-data-table
        v-if="teamMember.length"
        class="mx-5 mb-5"
        :search="search"
        :headers="tableColumns"
        :items="teamMember"
        :footer-props="{
          'items-per-page-options': [5, 10],
          'items-per-page-text': 'Jumlah data',
        }"
        disable-sort
      >
        <template v-slot:item.name="props">
          <div class="d-flex align-center">
            <v-avatar
              size="36"
              class="v-avatar-light-bg mr-2"
            >
              <v-img
                v-if="props.item.photo"
                :src="props.item.photo"
              />
              <span v-else>{{ avatarText(`${props.item.first_name} ${props.item.last_name}`) }}</span>
            </v-avatar>
            <div class="d-flex flex-column">
              <router-link :to="{ name: 'user-detail', params: { id: props.item.id } }">
                <span class="primary--text font-medium">{{ props.item.name }}</span>
              </router-link>
              <small class="text--disabled">{{ props.item.email }}</small>
            </div>
          </div>
        </template>
        <template
          v-if="teamData.user.id == $store.getters.getUserData.id"
          v-slot:item.action="props"
        >
          <v-btn
            v-if="props.item.id != $store.getters.getUserData.id"
            small
            outlined
            @click="removeUserFromTeam(props.item.id)"
          >
            Remove
          </v-btn>
        </template>
        <template
          v-slot:footer.page-text="page"
        >
          Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, maxLength } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { removeFromTeam } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  setup(props, { emit }) {
    const search = ref('')
    const showForm = ref(false)
    const teamMember = ref([])
    const teamData = ref([])
    const tableColumns = ref([
      {
        text: 'Nama',
        value: 'name',
        class: 'w-vdt-220',
      },
      {
        text: '',
        value: 'action',
        align: 'end',
      },
    ])
    const loadingSubmit = ref(false)

    const show = () => {
      showForm.value = true
    }

    const update = team => {
      showForm.value = true

      teamData.value = team
      teamMember.value = team.users
    }

    const close = () => {
      showForm.value = false
    }

    const removeUserFromTeam = id => {
      Vue.$dialog({
        title: 'Keluarkan dari tim?',
        body: 'Konfirmasi jika anda ingin mengeluarkan user ini.',
      }).then(confirm => {
        if (confirm) {
          apolloClient.mutate({
            mutation: removeFromTeam,
            variables: {
              user_id: id,
              team_id: teamData.value.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengeluarkan user!',
            })
            teamMember.value = teamMember.value.filter(member => member.id !== id)
            emit('success', result)
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      showForm,
      search,
      loadingSubmit,
      update,
      tableColumns,
      teamMember,
      teamData,
      removeUserFromTeam,

      show,
      close,

      required,
      maxLength,
      avatarText,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
