import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","scrollable":""},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"text-h6 font-medium primary--text"},[_vm._v(" Daftar Member Team ")]),_c(VIcon,{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VTextField,{staticClass:"px-5 mb-3 mt-0",attrs:{"append-icon":"mdi-magnify","label":"Cari Member","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.teamMember.length)?_c(VDataTable,{staticClass:"mx-5 mb-5",attrs:{"search":_vm.search,"headers":_vm.tableColumns,"items":_vm.teamMember,"footer-props":{
        'items-per-page-options': [5, 10],
        'items-per-page-text': 'Jumlah data',
      },"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg mr-2",attrs:{"size":"36"}},[(props.item.photo)?_c(VImg,{attrs:{"src":props.item.photo}}):_c('span',[_vm._v(_vm._s(_vm.avatarText(((props.item.first_name) + " " + (props.item.last_name)))))])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: props.item.id } }}},[_c('span',{staticClass:"primary--text font-medium"},[_vm._v(_vm._s(props.item.name))])]),_c('small',{staticClass:"text--disabled"},[_vm._v(_vm._s(props.item.email))])],1)],1)]}},(_vm.teamData.user.id == _vm.$store.getters.getUserData.id)?{key:"item.action",fn:function(props){return [(props.item.id != _vm.$store.getters.getUserData.id)?_c(VBtn,{attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.removeUserFromTeam(props.item.id)}}},[_vm._v(" Remove ")]):_vm._e()]}}:null,{key:"footer.page-text",fn:function(page){return [_vm._v(" Melihat "+_vm._s(page.pageStart)+" - "+_vm._s(page.pageStop)+" dari total "+_vm._s(page.itemsLength)+" data ")]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }